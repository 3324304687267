import router from '@/router'

export default (destination, allow = []) => {
  if (!destination) return null
  const resolved = router.resolve(destination)
  if ((typeof destination === 'object' || destination[0] === '/') && resolved) {
    router.push(resolved)
    return resolved
  }
  if (!allow?.length) return null
  if (typeof destination !== 'string') return null
  const { host } = new URL(destination)
  const found = allow.find(hostAllowed => hostAllowed === host)
  if (!found) {
    console.warn('Redirect not allowed to destination:', destination)
    return null
  }
  window.location.href = destination
  return destination
}
