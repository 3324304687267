<template>
  <div class="gap-4 max-w-6xl xl:max-w-full mx-auto" :key="componentData.isNieuw">
    <div class="card shadow flex flex-col gap-4 items-start">
      <UIInput required v-model="form.titel" label="Titel" class="w-full max-w-2xl" placeholder="Titel ..." />
      <UIInputDatePicker required label="Datum en tijd" class="text-left" timepicker v-model:date="form.datum" v-model:time="form.tijd" />
      <UIInputLabel class="block w-full">Chauffeurs</UIInputLabel>
      <div v-for="(id, index) in form.user_ids" :key="`${id}-${index}`" class="-mt-2 w-full max-w-sm flex flex-row justify-center">
        <UISelectChauffeur active class="flex-1" v-model="form.user_ids[index]" />
        <button :disabled="isChauffeursDisabled(form.user_ids[index])" @click="form.user_ids.splice(index, 1)" class="inline-flex justify-center items-center p-2 group">
          <i class="fas fa-times" :class="{ 'group-hover:text-red-500': !isChauffeursDisabled(form.user_ids[index]) }"></i>
        </button>
      </div>
      <button class="m-0" @click="form.user_ids.push(null)">+ Chauffeur toevoegen</button>
      <GoogleTussenstops class="flex flex-col gap-2 max-w-2xl" :disabled="disabled" v-model="form.adressen" label="Adressen" placeholder="Adres" />
      <UIInput textarea v-model="form.opmerking" label="Opmerking" class="w-full max-w-3xl" rows="4" placeholder="Opmerking ..." />
      <button type="submit" :disabled="!form.titel || !form.datum" class="btn success" @click.prevent.stop="onSubmit()">Opslaan</button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed, unref } from 'vue'
import clone from 'just-clone'

import GoogleTussenstops from '@/components/Google/Tussenstops.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIInput from '@/components/UI/Input/Input'
import UIInputLabel from '@/components/UI/Input/Label'

import { dateString, timeString } from '@/functions/formatDate'
import { useStore } from 'vuex'

const props = defineProps({
  disabled: Boolean,
  modelValue: Object,
})

const store = useStore()
const emit = defineEmits(['update:modelValue', 'submit'])

const date = new Date(props.modelValue?.datum || props.modelValue?.date || (Date.now() + 86400000))

const generateForm = (modelValue) => {
  const obj = {
    id: modelValue?.id || null,
    titel: modelValue?.titel || '',
    user_ids: modelValue?.user_ids || modelValue?.user_id || store.state.profile?.id,
    adressen: (modelValue?.adressen || []).map(el => ({ adres: el.adres || el })).filter(el => !!el),
    datum: dateString(modelValue?.datum || modelValue?.date || date),
    tijd: timeString(modelValue?.tijd || modelValue?.datum || modelValue?.date || date),
    opmerking: modelValue?.opmerking || '',
  }
  if (!Array.isArray(obj.user_ids)) {
    obj.user_ids = [obj.user_ids]
  }
  return obj
}

const form = ref(generateForm(unref(props.modelValue)))
watch(form, (val) => emit('update:modelValue', val))
watch(() => props.modelValue, (val) => {
  if (val && JSON.stringify(val) !== JSON.stringify(form.value)) {
    form.value = generateForm(val)
  }
})
onMounted(() => emit('update:modelValue', form.value))

const isChauffeursDisabled = computed(() => (user_id) => (user_id && form.value.user_ids?.filter(el => !!el).length <= 1) || props.disabled)

const componentData = ref({
  isNieuw: true,
})

const onSubmit = () => {
  const submitData = clone(form.value)
  submitData.user_ids = submitData.user_ids.filter(el => !!el)
  submitData.adressen = submitData.adressen.map(el => el.adres || el).filter(el => !!el)
  emit('submit', submitData)
}
</script>
