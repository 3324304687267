<template>
  <div class="w-full">
    <UIInputLabel :required="required" v-if="label">{{ label }}</UIInputLabel>
    <div class="flex flex-row" v-for="(_, index) in tussenstops" :key="`tussen-${index}-${tussenstops.length}`">
      <GoogleInput
        v-model="tussenstops[index]"
        :placeholder="`${placeholder} ${ index + 1 }...`"
        :disabled="disabled"
      />
      <button :disabled="disabled" @click="tussenstops.splice(index, 1)" class="inline-flex justify-center items-center p-2 group">
        <i class="fas fa-times" :class="{ 'group-hover:text-red-500': !disabled }"></i>
      </button>
    </div>
    <div>
      <button :disabled="disabled || tussenstops.length >= 20" @click="tussenstops.push(null)">+ {{ placeholder }} toevoegen</button>
    </div>
  </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core'

import UIInputLabel from '@/components/UI/Input/Label.vue'
import GoogleInput from '@/components/Google/Input.vue'

const props = defineProps({
  disabled: Boolean,
  required: Boolean,
  label: {
    type: String,
    default: 'Tussenstops',
  },
  placeholder: {
    type: String,
    default: 'Tussenstop',
  },
  modelValue: {
    type: Array,
    default() { return [null, null] },
  },
})

const emit = defineEmits(['update:modelValue'])
const tussenstops = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
</script>
