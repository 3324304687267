import { isDate, dateString } from '@/functions/formatDate'
import useRouteQueryBase from './_useRouteQueryBase'

const checkFn = isDate
const parseFn = x => (x === '' || x === 0 || x === null) ? null : dateString(new Date(x))
const defaultValueFn = () => Date.now()

export default useRouteQueryBase(checkFn, parseFn, defaultValueFn)

// import { useRoute, useRouter } from 'vue-router'
// import { isDate, dateString } from '@/functions/formatDate'
// import { watch, ref } from 'vue'
// import useRouteQuerySet from './useRouteQuerySet'

// export default (queryName, defaultValue = Date.now()) => {
//   const route = useRoute()
//   const router = useRouter()
//   const defaultValueSafe = isDate(defaultValue) ? defaultValue : Date.now()
//   const reactiveValue = ref(
//     dateString(new Date(isDate(route.query[queryName]) ? route.query[queryName] : defaultValueSafe)),
//   )
//   const setRoute = val => useRouteQuerySet(queryName, !val ? null : dateString(val), router, route)
//   watch(() => reactiveValue.value, setRoute)
//   setRoute(reactiveValue.value)
//   return reactiveValue
// }
